.login-body{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-form{
    max-width: 400px;
    width: 80%;

    // button{
    //   margin-left: 50%;
    // }
  }
  .google-icon{
    height: 25px;
  }
  .button-group-login{
    display: flex;
    justify-content: space-evenly;
    width: auto;
  }
}