.search-body{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 1000px;
  max-width: 90vw;
  margin: 20px auto;

  .search-param{
    text-transform: capitalize;
  }
  .search-form{
    width: 100%;
    display: flex;
    justify-content: center;
    
    .search-input{
      width: 70%;
      margin-left: 5px;
    }
  }

  .exception-buttons{
    display: flex;
    justify-content: center;
    padding: 10px 0;
    .exception-button{
      margin: 0 5px;
    }
  }
}