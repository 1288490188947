.data-table-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .data-button-group{
    display: flex;
    margin-bottom: 20px;
    width: fit-content;

    input{
      border-radius: 5px;
      border: 1px solid #000;
      padding: 5px;
    }
    *{
      margin: 0 10px;
    }

  }

  .data-table {
    max-width: 1000px;
    cursor: pointer;
  }
}
