.feedback-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  .email-me-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .update-table {
    max-width: 800px;
  }
}
