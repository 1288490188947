.report-container{
display: flex;
justify-content: center;
  .button-group{
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    .display-box{
      min-height: 240px;
      width: 50%;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: black;
      cursor: pointer;

      i, .bi{
        font-size: 35px;
      }
    }

  }
}