#autocomplete{
  width: 100%;
}
.pac-container {
  background-color: #FFF;
  z-index: 1200!important;
  // position: fixed;
  // display: inline-block;
  // float: left;
}

.display-box-modal{
  margin-bottom: 15px;

  button{
    width: 100%;
    font-size: 30px;
    padding-bottom: 12px;
  }
}
.modal-footer{
  display: flex;
  justify-content: space-between;
}