.home-body {
  width: 100vw;
  height: 91.5vh;
  .home-logged-out {
    position: relative;
    width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lottie-bg2{

    }
    .lottie-bg {
      position: absolute;
      overflow: hidden;
      // display: flex;
      // justify-content: center;
      width: 100vw;
      // top: 1vh;
      * {
        max-width: none;
        width: 100%;
        min-width: 1400px;
      }
    }
    .home-hero {
      z-index: 2;
      width: 100%;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      text-shadow: -1px 1px 0 #000;
      background-position: center;
      background-size: cover;
      // &:before{
      //   content: "";
      //   position: absolute;
      //   width: 900px;
      //   max-width: 100vw;
      //   height: 300px;
      //   // background: radial-gradient(circle at center, #fff, transparent);
      //   z-index: -1;
      // }
      h1,
      h2 {
        font-weight: 700;
        // background: radial-gradient(circle at center, #fff, transparent);
      }
      .tb-title {
        width: 90vw;
        max-width: 900px;
      }
      .raccoon {
        width: 300px;
      }
      .home-button-group {
        margin-top: 30px;
        width: 30%;
        min-width: 300px;
        display: flex;
        justify-content: space-evenly;
        // button {
        //   color: black;
        //   // text-shadow: -1px 1px 0 #fff;
        //   border-color: black;
        // }
      }
    }
  }
}
// Define colors
$button-bg: #fff; // Dark background color
$button-border-blue: #3fa9f5; // Lighter blue for border
$button-border-green: #7ed957; // Green for neon border
$button-text: #000; // White text color

// Mixin for button style
@mixin button-style($border-color) {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  background: $button-bg;
  border: 3px solid $border-color;
  color: $button-text;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: lighten($button-bg, 5%);
    color: darken($button-text, 10%);
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 2px lighten($border-color, 20%);
  }
}

// Button 1 with blue border
.login-button-blue {
  @include button-style($button-border-blue);
}

// Button 2 with green neon border
.login-button-green {
  @include button-style($button-border-green);
}

// Usage in HTML
// <a href="#" class="login-button-blue">Login</a>
// <a href="#" class="login-button-green">Login</a>
